import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PublicService } from "src/app/public/services/public.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { Product } from "../interfaces/product";
import { SellsheetCategory } from "./sellsheet-template-toc-pbna/sellsheet-template-toc-pbna.component";
import { Category } from "../services/category.service";

@Component({
  selector: "fc-sellsheet",
  templateUrl: "./sellsheet.component.html",
  styleUrls: ["./sellsheet.component.scss"],
})
export class SellsheetComponent implements OnInit {
  tempProducts: Product[] = [];
  products: Product[] = [];
  categories: SellsheetCategory[] = [];
  offsetPage: number = 2;

  constructor(
    private publicService: PublicService,
    private route: ActivatedRoute,
    public sharedService: SharedService,
  ) { }

  ngOnInit() {
    document.getElementById("wrapper")!.style.height = "unset";

    const objectIdParam = this.route.snapshot.paramMap.get("objectId");
    const typeParam = this.route.snapshot.paramMap.get("type");

    if (objectIdParam) {
      if (typeParam && typeParam === "product") {
        this.getProduct(parseInt(objectIdParam))
      } else {
        if (objectIdParam === "full") {
          this.getAllCategoriesWithProducts();
        } else {
          this.getCategoryWithProducts(parseInt(objectIdParam));
        }
      }
    }
  }

  private getProduct(productId: number): void {
    this.publicService.getProductByid(productId).subscribe(productData => {
      if (productData.hasSellSheet) {
        this.tempProducts.push(productData)
        const productsIds = this.tempProducts.map(product => product.id)
        this.products = this.tempProducts.filter(({ id }, index) => !productsIds.includes(id, index + 1))
      }
    }, (error) => {
      console.error("there was an error sending the query", error);
    })
  }

  private getCategoryWithProducts(categoryId: number): void {
    this.publicService.getCategoryWithProducts(categoryId).subscribe(
      (categoryWithProductsData) => {
        categoryWithProductsData.children?.forEach((categoryDataChildren) => {
          if (categoryDataChildren.products && categoryDataChildren.products.length > 0) {
            this.sort(categoryDataChildren);
          }

          categoryDataChildren["children"]?.forEach(
            (categoryDataChildrenChildren) => {
              if (categoryDataChildrenChildren.products && categoryDataChildrenChildren.products.length > 0) {
                this.sort(categoryDataChildrenChildren);
              }
            }
          );
        });

        categoryWithProductsData.children?.forEach((categoryDataChildren) => {
          if (categoryDataChildren.products && categoryDataChildren.products.length > 0) {
            this.tempProducts.push(...categoryDataChildren.products);
          }

          categoryDataChildren["children"]?.forEach(
            (categoryDataChildrenChildren) => {
              if (categoryDataChildrenChildren.products && categoryDataChildrenChildren.products.length > 0) {
                this.tempProducts.push(
                  ...categoryDataChildrenChildren.products
                );
              }
            }
          );
        });

        this.tempProducts = this.tempProducts.filter(function (e) {
          return e.hasSellSheet === true;
        });

        const productsIds = this.tempProducts.map(product => product.id)
        this.products = this.tempProducts.filter(({ id }, index) => !productsIds.includes(id, index + 1))
      },
      (error) => {
        console.error("there was an error sending the query", error);
      }
    );
  }

  private getAllCategoriesWithProducts(): void {
    this.publicService.getAllCategoriesWithProducts().subscribe(
      (categoriesWithProductsData) => {

        if (this.sharedService.environmentIsPBNA()) {
          // Merge all Flex products into one subcategory (and Electric Barrel)
          // Also merge all subcategories into 1 category and sort them
          const mainCategory: Category = {
            id: 0,
            name: 'main',
            children: [],
          };
          categoriesWithProductsData.forEach((categoriesWithProductsData) => {
            if (categoriesWithProductsData.name === 'Flex' || categoriesWithProductsData.name === 'Electric Barrel') {
              if (categoriesWithProductsData.children) {
                const products = categoriesWithProductsData.children.reduce((acc, c) => {
                  if (c.products) {
                    acc.push(...c.products)
                  }
                  return acc;
                }, [] as Product[]);
                categoriesWithProductsData.children = [{
                  id: categoriesWithProductsData.children[0].id,
                  name: categoriesWithProductsData.name,
                  products: products,
                }]
              }
            }

            mainCategory.children?.push(...categoriesWithProductsData.children ?? []);
            mainCategory.children?.sort((a, b) => a.name > b.name ? 1 : -1);
          });
          categoriesWithProductsData = [mainCategory];
        }


        categoriesWithProductsData.forEach((categoriesWithProductsData) => {
          categoriesWithProductsData.children?.forEach(
            (categoryDataChildren) => {
              if (categoryDataChildren.products && categoryDataChildren.products.length > 0) {
                this.sort(categoryDataChildren);
              }

              categoryDataChildren["children"]?.forEach(
                (categoryDataChildrenChildren) => {
                  if (categoryDataChildrenChildren.products && categoryDataChildrenChildren.products.length > 0) {
                    this.sort(categoryDataChildrenChildren);
                  }
                }
              );
            }
          );
        });

        const categories: SellsheetCategory[] = [];
        categoriesWithProductsData.forEach((categoriesWithProductsData) => {
          categoriesWithProductsData.children?.forEach(
            (categoryDataChildren) => {
              if (categoryDataChildren.products && categoryDataChildren.products.length > 0) {
                this.tempProducts.push(...categoryDataChildren.products);

                if (categoryDataChildren.products.some(p => p.hasSellSheet)) {
                  categories.push({
                    name: categoryDataChildren.name,
                    fragment: categoryDataChildren.products[0].id.toString(),
                  });
                }
              }

              categoryDataChildren["children"]?.forEach(
                (categoryDataChildrenChildren) => {
                  if (categoryDataChildrenChildren.products && categoryDataChildrenChildren.products.length > 0) {
                    this.tempProducts.push(
                      ...categoryDataChildrenChildren.products
                    );
                    if (categoryDataChildrenChildren.products.some(p => p.hasSellSheet)) {
                      categories.push({
                        name: categoryDataChildrenChildren.name,
                        fragment: categoryDataChildrenChildren.products[0].id.toString(),
                      });
                    }
                  }
                }
              );
            }
          );
        });


        this.tempProducts = this.tempProducts.filter(function (e) {
          return e.hasSellSheet === true;
        });

        const productsIds = this.tempProducts.map(product => product.id)
        this.products = this.tempProducts.filter(({ id }, index) => !productsIds.includes(id, index + 1));
        this.categories = categories;
        this.offsetPage = 2 + Math.floor(this.categories.length / 20);
      },
      (error) => {
        console.error("there was an error sending the query", error);
      }
    );
  }

  private sort(element: any) {
    if (element.products.length > 0) {
      element.products.sort(function (a: any, b: any) {
        return a.sortId - b.sortId;
      });
    }
  }
}
