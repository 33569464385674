import { Component, OnInit, Input } from '@angular/core';

import { CoreService } from 'src/app/core/services/core.service';
import { DevicesService } from 'src/app/core/services/devices.service';
import { SharedService } from 'src/app/shared/services/shared.service'
import { MenuCategory } from '../../services/menu-categories.service';

import { AuthService } from "src/app/public/auth/auth.service";

import { ClientFunctions } from 'src/app/shared/interfaces/client-functions';

@Component({
  selector: 'fc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isFsvSecureAccess: boolean = false;

  isMobile: boolean = false
  menuCategoriesData: MenuCategory[] = [];

  clientFunctions: ClientFunctions = {
    mcoe: {},
    fsv: {},
    pbna: {},
  }

  constructor(
    private devicesService: DevicesService,
    public sharedService: SharedService,
    private coreService: CoreService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile()

    this.clientFunctions = {
      mcoe: {
        faqClicked: () => {
          this.navigateToPdfName('faq')
        },
        usellClicked: () => {
          this.navigateToPdfName('usell')
        },
        temporaryOrderClicked: () => {
          this.navigateToPdfName('temporary-order')
        },
        epopClicked: () => {
        }
      },
      fsv: {
        faqClicked: () => {
          this.navigateToPdfName('21080_067_FSV_Sales_Merchandising_Catalog_FAQ_RB01')
        },
        usellClicked: () => {
          window.open("https://pepsico.sharepoint.com/sites/FLNAAFHDistributors/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FFLNAAFHDistributors%2FShared%20Documents%2FBrands%2FFrito%2DLay%2FMerchandising%2FuSell&viewid=fbc37cfa%2Dce88%2D4589%2Db8d5%2De769408e0623", "_blank");
        },
        epopClicked: () => {
          window.open("https://pepsico.sharepoint.com/sites/Flna_Sales/mcoe/away_from_home/SitePages/Reports.aspx", "_blank");
        }
      },
      pbna: {
        faqClicked: () => {
          this.navigateToPdfName('pbna_how_to_order');
        },
        contactClicked: () => {
          this.navigateToPdfName('pbna_contact');
        },
      }
    }

    this.getMenuCategories()
  }

  private getMenuCategories(): void {
    this.coreService.getMenuCategories()
      .subscribe(menuCategoriesData => {
        this.menuCategoriesData = menuCategoriesData;
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  navigateToCategoryName(categoryName: string) {
    this.sharedService.navigateToCategoryName(categoryName)
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName)
  }

  navigateToPdfName(pdfName: string) {
    this.sharedService.navigateToPdf(pdfName)
  }

  navigateToCategoryId(categoryId: number) {
    this.sharedService.navigateToCategoryId(categoryId)
  }

  openSellSheets() {
    window.open("https://pepsico.sharepoint.com/:f:/r/sites/FLNAAFHDistributors/Shared%20Documents/Merchandising/Frito%20Lay/FLNA%20Equipment%20Catalog/Equipment%20Sell%20Pages?csf=1&web=1&e=mbsIui", "_blank")
  }

  getFooterYear(): string {
    const currentDate = new Date()
    const year = currentDate.getFullYear().toString()

    return year
  }
}
